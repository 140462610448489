export const LIFE_COLORS = {
  primary_20: '#F1FBFE',
  primary_050: '#E3F5FC',
  primary_100: '#D4E9F2',
  primary_200: '#A9D3E5',
  primary_300: '#7FBCD7',
  primary_400: '#519DBD',
  primary_500: '#2E7FA1', // 500 Core
  primary_600: '#276D8B',
  primary_700: '#215369',
  primary_800: '#163746',

  accent1_20: '#EDFCFB',
  accent1_50: '#E4F6F6',
  accent1_100: '#C8EEEC',
  accent1_200: '#98D8D5',
  accent1_300: '#6BC2BE',
  accent1_400: '#3EA39E',
  accent1_500: '#25837E', // 500 Core
  accent1_600: '#23716D',
  accent1_700: '#145653',
  accent1_800: '#0F3937',

  accent2_20: '#FFFAEE',
  accent2_50: '#FDF0DE',
  accent2_100: '#F8E2BF',
  accent2_200: '#EAC785', // 200 Core
  accent2_300: '#D6AD61',
  accent2_400: '#B58E45',
  accent2_500: '#92702F',
  accent2_600: '#806128',
  accent2_700: '#604920',
  accent2_800: '#42300F',

  correct_20: '#ECFEF1',
  correct_100: '#CCEED2',
  correct_200: '#A0D9AD',
  correct_300: '#77C589',
  correct_400: '#37A851', // 400 Core
  correct_500: '#14892F',
  correct_600: '#13772A',
  correct_700: '#025A15',
  correct_800: '#003D0E',

  warning_20: '#FEF7F1',
  warning_050: '#FDF0E7',
  warning_100: '#FCDDC5',
  warning_200: '#F8C096',
  warning_300: '#F4A162',
  warning_400: '#E97116', // 400 Core
  warning_500: '#C45402',
  warning_600: '#AD4B00',
  warning_700: '#803700',
  warning_800: '#572600',

  critical_050: '#FEECF1',
  critical_20: '#FEF5F7',
  critical_100: '#FDDEE2',
  critical_200: '#FDBAC2',
  critical_300: '#FD96A4',
  critical_400: '#FC5A6F',
  critical_500: '#DC3449', // 500 Core
  critical_600: '#C32238',
  critical_700: '#9C0D1D',
  critical_800: '#6B0613',

  neutral_050: '#F2F2F2',
  neutral_20: '#FAFAFA', // 020 Core
  neutral_50: '#F2F2F2',
  neutral_100: '#E5E5E5',
  neutral_200: '#CCCCCC',
  neutral_300: '#B3B3B3',
  neutral_400: '#949494',
  neutral_500: '#767676',
  neutral_600: '#666666',
  neutral_700: '#4D4D4D',
  neutral_800: '#333333',

  indigo_400: '#8091D1',
  indigo_600: '#5062A5',
} as const;

export const COLORS = {
  // include LIFE_COLORS
  ...LIFE_COLORS,

  // add semantic color names
  black: '#1F1F1F', // Neutrals / Black
  white: '#FFFFFF', // Neutrals / White
  grey: LIFE_COLORS.neutral_400, // Neutrals / grey
  primary: LIFE_COLORS.primary_500,
  accent1: LIFE_COLORS.accent1_500,
  accent2: LIFE_COLORS.accent2_200,
  correct: LIFE_COLORS.correct_400,
  warning: LIFE_COLORS.warning_400,
  critical: LIFE_COLORS.critical_500,

  focus: 'rgba(46, 127, 161, 1)', // Semantic / Focus 100%
  focus_25: 'rgba(46, 127, 161, 0.25)', // Semantic / Focus 25%

  white_gradient:
    'linear-gradient(255deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%)',
} as const;

export default COLORS;
